window.onload = function () {
  var checkedRadio = document.querySelector(".menu__radio:checked");
  var sliderInterval;

  function highlightLabel(radio) {
    var label = document.querySelector('label[for="' + radio.id + '"]');
    label.style.background = "rgba(0, 0, 0, 0.4)";
  }

  function removeHighlight() {
    var labels = document.querySelectorAll(".menu__label");
    [].forEach.call(labels, function (label) {
      label.style.background = "rgba(0, 0, 0, 0.1)";
    });
  }

  function autoChange() {
    var radios = document.querySelectorAll(".menu__radio");
    var checkedIndex = Array.from(radios).findIndex((radio) => radio.checked);
    var nextIndex = (checkedIndex + 1) % radios.length;
    radios[nextIndex].checked = true;
    removeHighlight();
    highlightLabel(radios[nextIndex]);
  }

  function startSlider() {
    sliderInterval = setInterval(autoChange, 4000);
  }

  function stopSlider() {
    clearInterval(sliderInterval);
  }

  // Highlight the label of the checked radio button
  if (checkedRadio) {
    highlightLabel(checkedRadio);
  }

  // Add click event listener to all radio buttons
  [].forEach.call(document.querySelectorAll(".menu__radio"), function (el) {
    el.addEventListener("click", function () {
      removeHighlight();
      highlightLabel(this);
      stopSlider();
      startSlider();
    });
  });

  // Start the slider interval on page load
  startSlider();
};
